import "./hs.core.js";
import "./hs.chartjs.js";
import "./hs.circles.js";
import "./hs.clipboard.js";
import "./hs.countdown.js";
import "./hs.cubeportfolio.js";
import "./hs.dropzone.js";
import "./hs.fancybox.js";
import "./hs.flatpickr.js";
import "./hs.ion-range-slider.js";
import "./hs.leaflet.js";
import "./hs.mask.js";
import "./hs.select2.js";
import "./hs.slick-carousel.js";
import "./hs.tagify.js";
import "./hs.validation.js";
import "./theme-custom.js";


frappe.ready(() => {
	// initialization of HSMegaMenu component
	new HSMegaMenu($(".js-mega-menu")).init();
	// initialization of header
	new HSHeader($("#header")).init();
	AOS.init();

	$(".js-validate").each(function () {
	  $.HSCore.components.HSValidation.init($(this));
	});

	// Pre-fill form fields from url parameters
	(new URL(window.location.href)).searchParams.forEach((x, y) => {
		element = document.getElementById(y);
		if(element) {
			element.value = x;
		}
	});
});
